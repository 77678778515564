import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const routes = [
  // 首页
  // {
  //   path: '/',
  //   name: 'home',
  //   component: () => import("../views/Home.vue")
  // },
  // {
  //   path: '/partner',
  //   name: 'partner',
  //   component: () => import("../views/partner.vue")
  // },
  {
    path: '/',
    name: 'limited',
    component: () => import("../views/limited.vue")
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


export default router
